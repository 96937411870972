import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import { lightTheme, darkTheme } from '@/config'
export const vuetifyOptions = {
    icons: {
        iconfont: `mdiSvg`
    },
    theme: {
        dark: false,
        themes: {
            light: lightTheme,
            dark: darkTheme
        }
    }
}

export default new Vuetify(vuetifyOptions)

Vue.use(Vuetify)
