<template>
    <transition appear>
        <v-card
            class="d-flex align-center justify-center"
            flat
            min-height="85vh"
        >
            <v-col class="subtitle-1 text-center" cols="12"> Loading.. </v-col>
        </v-card>
    </transition>
</template>

<script>
export default {
    page: {
        title: `Loading page...`,
        meta: [{ name: `description`, content: `Loading page...` }]
    }
}
</script>
