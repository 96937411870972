<script>
import { shipItemsList } from '@/assets/data/reported_problems.js'
import formRules from '@/utils/form_rules'
import { mdiEye, mdiEyeOff } from '@mdi/js'
import { gql } from '@apollo/client'
import { discountCalculation } from '@/mixins/discount'
import RepairTypeMixin from '@/mixins/repair_type'

export default {
    name: `ProblemForm`,
    mixins: [RepairTypeMixin, discountCalculation('formInput.problemPrice')],
    inheritAttrs: false,
    props: {
        pn: {
            type: String,
            default: ``
        },
        model: {
            type: String,
            default: ``
        },
        serialIsValid: {
            type: Boolean,
            required: true
        },
        colMd: {
            type: String,
            default: ``
        },
        allowOow: {
            type: Boolean,
            required: false,
            default: false
        },
        inWarranty: {
            type: Boolean,
            default: false
        },
        productType: {
            type: String,
            default: ``
        },
        serial: {
            type: String,
            default: ``
        }
    },

    apollo: {
        repairFormSelections: {
            query: gql`
                query repairFormSelections($pn: String!, $model: String!, $rmaType: RmaType, $serial: String) {
                    repairFormSelections(pn: $pn, model: $model, rmaType: $rmaType, serial: $serial) {
                        name
                        options {
                            name
                            price
                            tip
                        }
                    }
                }
            `,
            skip() {
                return !this.pn || !this.pn.length
            },
            variables() {
                return {
                    model: this.model,
                    pn: this.pn,
                    rmaType: this.repairType,
                    serial: this.serial
                }
            }
        }
    },

    data() {
        return {
            shipItemsList,
            mdiEye,
            mdiEyeOff,
            formRules,
            formValid: false,
            showPassword1: false,
            showPassword2: false,
            formInput: {
                permissions: {
                    acknowledgeHddBackup: false,
                    reimage: false,
                    biosFirmware: false
                },
                acc: {
                    acPower: false,
                    storage: false,
                    hardCase: false,
                    battery: false
                },
                problemPrice: ''
            }
        }
    },

    computed: {
        isGvsProduct() {
            return this.productType === 'VERETOS'
        },
        gvsRmaClickwrap() {
            return process.env.VUE_APP_PUBLIC_ASSETS_URL + '/documents/RMA Portal Clickwrap (3.21.23 v2).docx'
        },
        formLoading() {
            return !!this.$apollo.queries.repairFormSelections.loading
        },
        primaryFaultList() {
            return (this.repairFormSelections || []).map((e) => e.name).sort()
        },
        subProblemsList() {
            const primary = (this.repairFormSelections || []).find((e) => e.name === this.formInput.primaryFault)
            return primary
                ? primary.options
                      .filter((e) => this.inWarranty || e.price > 0)
                      .map((e) => ({ name: e.name, price: e.price }))
                      .sort()
                : []
        },
        suggestedRepair() {
            const primary = (this.repairFormSelections || []).find((e) => e.name === this.formInput.primaryFault)

            if (primary) {
                const problem = primary.options.find((e) => e.name === this.formInput.problem)

                if (problem) {
                    return problem.tip || ``
                }
            }

            return ``
        }
    },

    watch: {
        primaryFaultList(value) {
            if (value.length === 1) {
                this.formInput.primaryFault = value[0]
            }
        },
        serial: {
            immediate: true,
            handler() {
                this.updatePrimaryFault()
            }
        }
    },

    methods: {
        updatePrimaryFault() {
            delete this.formInput.problem
            delete this.formInput.problemPrice
            this.emitInput()
        },
        async resetForm() {
            this.$refs.form.reset()
            await this.$nextTick()
            this.formInput.permissions.reimage = false
            this.formInput.permissions.biosFirmware = false
            this.formInput.acc.acPower = false
            this.formInput.acc.storage = false
            this.formInput.acc.hardCase = false
            this.formInput.acc.battery = false
        },
        async selectProblem() {
            if (!this.inWarranty) {
                const primary = (this.repairFormSelections || []).find((e) => e.name === this.formInput.primaryFault)

                if (primary) {
                    const problem = primary.options.find((e) => e.name === this.formInput.problem)

                    this.formInput.problemPrice = problem.price
                }
                this.emitInput()
            }
            if (this.inWarranty) {
                this.emitInput()
            }
        },
        emitValidation(v) {
            this.$emit(`form-is-valid`, !!(this.formValid && this.serialIsValid))
        },
        async emitInput() {
            await this.$nextTick()
            this.$emit(`problemInput`, this.formInput)
        }
    }
}
</script>

<template>
    <v-form ref="form" v-model="formValid" @input="emitValidation">
        <div>
            <v-row justify="space-between">
                <v-col cols="12" :md="colMd">
                    <v-card flat class="pr-5" style="background-color: transparent">
                        <slot name="serial-validation" />
                        <base-info-card
                            title="Primary Fault & Specific Problem"
                            color="primary"
                            subtitle="Please select a primary fault and problem from the 2 drop downs below"
                        />
                        <base-select
                            id="primaryFault"
                            v-model="formInput.primaryFault"
                            :rules="[formRules.required]"
                            :disabled="!serialIsValid || formLoading || primaryFaultList.length === 1"
                            :loading="formLoading"
                            :items="primaryFaultList"
                            dense
                            no-data-text="No options available at this time, please reach out to Getac support & service."
                            data-cy="form-primary-fault"
                            label="Primary Fault"
                            clearable
                            @input="updatePrimaryFault"
                        >
                        </base-select>

                        <base-select
                            id="problem"
                            v-model="formInput.problem"
                            dense
                            :rules="[formRules.required]"
                            :disabled="!serialIsValid || formLoading"
                            :loading="formLoading"
                            :items="subProblemsList"
                            :no-data-text="
                                subProblemsList.length
                                    ? 'Please select a Primary Fault first'
                                    : 'No options available at this time, please reach out to Getac support & service.'
                            "
                            label="Problem"
                            data-cy="form-problem"
                            clearable
                            item-text="name"
                            @input="selectProblem"
                        >
                        </base-select>

                        <v-simple-table
                            v-if="formInput.problem && !inWarranty"
                            dark
                            class="data-table-simple"
                            style="margin-bottom: 50px; margin-top: 25px"
                        >
                            <thead>
                                <tr>
                                    <th class="text-left"> Selection </th>
                                    <th class="text-left"> Cost (USD) </th>
                                    <th v-if="userHasDiscountAvailable" class="text-left">After Discount (USD)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {{ formInput.primaryFault }} -
                                        {{ formInput.problem }}
                                    </td>
                                    <td>${{ formInput.problemPrice }} </td>
                                    <td v-if="userHasDiscountAvailable">${{ subtotalAfterDiscount }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>

                        <!-- <v-card
                            v-if="!suggestedRepair"
                            min-height="160"
                            class="mx-auto"
                            flat
                            color="transparent"
                            data-cy="hidden-tip"
                        /> -->
                        <v-card
                            v-if="suggestedRepair"
                            rounded="lg"
                            data-cy="shown-tip"
                            min-height="160"
                            style="border: 1px solid #db5120 !important"
                            class="mx-auto suggested-repair-class"
                            outlined
                            color="transparent"
                        >
                            <base-info-card
                                title="Technician Tip:"
                                color="primary"
                                style="margin-left: 15px; margin-top: 10px; margin-bottom: -10px"
                            />
                            <v-card-text>
                                <div class="text--white" style="color: white !important" v-html="suggestedRepair"></div>
                            </v-card-text>
                        </v-card>
                    </v-card>
                </v-col>

                <v-col cols="12" :md="colMd">
                    <v-card flat class="pr-5" style="background-color: transparent">
                        <div class="d-flex flex-wrap">
                            <base-info-card title="Service Request Notes" subtitle="required*" color="primary" />
                            <base-textarea
                                id="initialNotes"
                                v-model="formInput.initialNotes"
                                clearable
                                dense
                                :rules="[formRules.required, formRules.maxLength(1000, 'characters')]"
                                data-cy="form-rma-notes"
                                :disabled="!serialIsValid"
                                rows="3"
                                @input="emitInput"
                            />
                            <base-info-card
                                v-if="!isGvsProduct"
                                title="System Image"
                                color="primary"
                                subtitle="Getac permitted to reimage the device with OEM image."
                            />

                            <v-radio-group
                                v-if="!isGvsProduct"
                                id="permissionsReimage"
                                v-model="formInput.permissions.reimage"
                                dense
                                :disabled="!serialIsValid"
                                row
                                @change="emitInput"
                            >
                                <v-radio label="Yes" data-cy="form-reimage" :value="true"></v-radio>
                                <v-radio label="No" :value="false" data-cy="form-deny-reimage"></v-radio>
                            </v-radio-group>
                            <base-info-card
                                v-if="!isGvsProduct"
                                title="Bios"
                                color="primary"
                                subtitle="Getac permitted to update BIOS & Firmware to latest revision."
                            />

                            <v-radio-group
                                v-if="!isGvsProduct"
                                id="permissionsBios"
                                v-model="formInput.permissions.biosFirmware"
                                dense
                                :disabled="!serialIsValid"
                                row
                                @change="emitInput"
                            >
                                <v-radio data-cy="form-approve-bios-upgrade" label="Yes" :value="true"></v-radio>
                                <v-radio data-cy="form-deny-bios-upgrade" label="No" :value="false"></v-radio>
                            </v-radio-group>
                            <base-info-card
                                v-if="!isGvsProduct"
                                title="Accessories Shipping with Device"
                                color="primary"
                            />

                            <div v-if="!isGvsProduct" class="d-flex flex-wrap">
                                <v-checkbox
                                    v-for="(item, i) in shipItemsList"
                                    :id="'acc-' + item.key"
                                    :key="i"
                                    v-model="formInput.acc[item.key]"
                                    dense
                                    :disabled="!serialIsValid"
                                    class="ma-2"
                                    :label="item.title"
                                    :data-cy="`form-ship-items-${item.key}`"
                                    @change="emitInput"
                                ></v-checkbox>
                            </div>
                        </div>
                    </v-card>
                </v-col>
                <v-col v-if="productType === 'VERETOS'" cols="12" :md="colMd">
                    <v-card flat class="pr-5" style="background-color: transparent">
                        <base-info-card title="Is there data on the device?" color="primary" subtitle="required*" />

                        <v-radio-group
                            id="gvsData"
                            v-model="formInput.permissions.hasDeviceData"
                            :rules="[formRules.requiredBoolean]"
                            dense
                            :disabled="!serialIsValid"
                            row
                            @change="emitInput"
                        >
                            <v-radio data-cy="form-gvs-data-true" label="Yes" :value="true"></v-radio>
                            <v-radio data-cy="form-gvs-data-false" label="No" :value="false"></v-radio>
                        </v-radio-group>
                        <v-alert
                            v-if="formInput.permissions.hasDeviceData"
                            border="bottom"
                            colored-border
                            type="warning"
                            elevation="2"
                        >
                            By submitting this form, you agree to Getac's
                            <a :href="gvsRmaClickwrap">RMA Agreement</a>.
                            <v-checkbox
                                v-model="formInput.permissions.acknowledgeClickWrapAgreement"
                                :disabled="!serialIsValid"
                                :rules="[formRules.required]"
                                label="Acknowledge the above statement(s)"
                            />
                        </v-alert>
                    </v-card>
                </v-col>
                <v-col cols="12" :md="colMd">
                    <v-card flat class="pr-5" style="background-color: transparent">
                        <base-info-card title="Reference Id." color="primary" subtitle="optional" />

                        <base-text-field
                            id="custReference"
                            v-model="formInput.custReference"
                            clearable
                            dense
                            data-cy="form-user-ref-id"
                            :rules="[formRules.maxLength(50, 'characters')]"
                            :disabled="!serialIsValid"
                            @input="emitInput"
                        />
                    </v-card>
                </v-col>
                <v-col cols="12" :md="colMd">
                    <v-card flat class="pr-5" style="background-color: transparent">
                        <base-info-card title="Asset Tag" color="primary" subtitle="optional" />

                        <base-text-field
                            id="assetTag"
                            v-model="formInput.assetTag"
                            clearable
                            dense
                            data-cy="form-user-asset-tag"
                            :disabled="!serialIsValid"
                            :rules="[formRules.maxLength(20, 'characters')]"
                            @input="emitInput"
                        />
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" :md="colMd">
                    <base-info-card
                        title="Disclaimer"
                        color="primary"
                        text="To protect your data from any loss and damages, you shall back up your personal data before returning the product(s). Getac assumes no liability for any loss or damages of data in connection with, or arising out of, the services (including but not limited to test performance and repair) you hereby request."
                    >
                        <div class="mt-2">
                            If your reported problem is determined to be due to a defective drive, your drive will be
                            replaced. Please be sure to backup your data prior to sending your device in.
                        </div>
                        <v-checkbox
                            v-model="formInput.permissions.acknowledgeHddBackup"
                            :disabled="!serialIsValid"
                            :rules="[formRules.required]"
                            label="Acknowledge the above statement(s)"
                        />
                    </base-info-card>
                </v-col>
                <v-col cols="12" :md="colMd">
                    <base-info-card
                        title="Notice"
                        color="primary"
                        text="To achieve the best result for repair, Getac will conduct
                                necessary test(s) for root cause determination. Should there be
                                any discrepancy between root cause found by Getac and the
                                problem stated hereunder, you hereby authorize Getac's personnel
                                to contact you, based on the personal information provided
                                hereunder, for your further instruction."
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" :md="colMd">
                    <base-info-card
                        title="WINDOWS LOGIN AND PASSWORD"
                        color="primary"
                        subtitle="optional"
                        text="Providing customer windows login and password allows the service technicians to test this product with the original hard drive. The login credentials will only be viewed by the service technicians. It would be considered best practice to change the password to something generic. Please be aware, should the windows login/password not be provided, it may lead to delays in processing your service request/upgrade/repair."
                    />
                </v-col>
                <v-col v-if="!isGvsProduct" cols="12" :md="colMd">
                    <base-info-card title="Windows Username" subtitle="optional" color="primary" />
                    <base-text-field
                        v-model="formInput.permissions.userId"
                        :rules="[formRules.maxLength(50, 'characters')]"
                        dense
                        clearable
                        data-cy="form-win-un"
                        :disabled="!serialIsValid"
                        @input="emitInput"
                    />
                    <base-info-card title="Windows Password" subtitle="optional" color="primary" />
                    <base-text-field
                        v-model="formInput.permissions.userPassword"
                        :rules="[formRules.maxLength(50, 'characters')]"
                        dense
                        clearable
                        :append-icon="showPassword2 ? mdiEye : mdiEyeOff"
                        :type="showPassword2 ? 'text' : 'password'"
                        data-cy="form-win-pw"
                        :disabled="!serialIsValid"
                        @input="emitInput"
                        @click:append="showPassword2 = !showPassword2"
                    />
                    <base-info-card title="Bios Password" subtitle="optional" color="primary" />
                    <base-text-field
                        v-model="formInput.permissions.biosPassword"
                        dense
                        clearable
                        :append-icon="showPassword1 ? mdiEye : mdiEyeOff"
                        :type="showPassword1 ? 'text' : 'password'"
                        hide-details
                        data-cy="form-bios-pass"
                        :rules="[formRules.maxLength(50, 'characters')]"
                        :disabled="!serialIsValid"
                        @input="emitInput"
                        @click:append="showPassword1 = !showPassword1"
                    />
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" :md="colMd"></v-col>
                <v-col cols="12" :md="colMd">
                    <slot name="file-uploader" />
                </v-col>
            </v-row>
        </div>
    </v-form>
</template>

<style>
.data-table-simple .theme--dark.v-simple-table > .v-simple-table__wrapper > table > tbody > tr > td,
.data-table-simple
    .theme--dark.v-simple-table
    > .v-simple-table__wrapper
    > table
    > tbody
    > tr
    > td:not(.v-simple-table__mobile-row),
.data-table-simple .theme--dark.v-simple-table > .v-simple-table__wrapper > table > tbody > tr > th:last-child,
.data-table-simple
    .theme--dark.v-simple-table
    > .v-simple-table__wrapper
    > table
    > tbody
    > tr
    > th:not(.v-simple-table__mobile-row),
.data-table-simple .theme--dark.v-simple-table > .v-simple-table__wrapper > table > thead > tr:last-child > th {
    border: none !important;
    background-color: transparent !important;
}
.data-table-simple .theme--dark.v-simple-table {
    background-color: transparent !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-spacing: 0 0.5rem !important;
}
.data-table-simple tr:not(.v-simple-table-header > tr) {
    margin: auto;
    padding: 10px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-image: linear-gradient(155deg, rgba(90, 90, 90, 0.6), rgba(90, 90, 90, 0.6));
    margin-bottom: 10px;
}
.data-table-simple .theme--dark.v-simple-table {
    background-color: transparent !important;
    color: #fff;
}
.data-table-simple > .v-simple-table__wrapper > table {
    border-spacing: 0 0.5rem;
    background-color: rgb(63, 63, 63);
}
.theme--dark.v-simple-table .v-data-footer {
    border: none;
    margin: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-image: linear-gradient(155deg, rgba(90, 90, 90, 0.6), rgba(255, 255, 255, 0));
    /* display: none; */
}
</style>
