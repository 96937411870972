<template>
    <section id="hero-alt">
        <v-container fluid class="px-0" :class="`pb-${spacing}`">
            <div v-if="title" :class="marginClass">
                <base-heading :title="title" dark space="3" weight="500" align="left" icon />

                <base-divider color="secondary" align="left" height=".25vw" space="1" max-width="100" />
            </div>
            <div class="pt-4">
                <slot />
            </div>
        </v-container>
    </section>
</template>

<script>
export default {
    name: `BaseHeroSimple`,

    provide: {
        heading: { align: `center` }
    },

    props: {
        spacing: {
            type: String,
            default: `0`
        }
    },

    metaInfo() {
        return {
            changed: (meta) => {
                return (
                    (this.title = meta.titleChunk.toUpperCase()), (this.subTitle = meta.subTitle ? meta.subTitle : ``)
                )
            }
        }
    },

    data: () => ({
        title: ``,
        subTitle: ``
    }),

    computed: {
        marginClass() {
            return this.$vuetify.breakpoint.mdAndUp ? `mt-8` : `mt-2`
        }
    }
}
</script>

<style lang="sass">
#hero-alt
  .v-breadcrumbs__item
    color: #FFFFFF
</style>
