<template>
    <v-container fluid class="pa-0">
        <v-row :justify="justify" no-gutters>
            <v-col v-if="icon" :class="`text-${align}`" cols="12" class="mb-4">
                <base-icon :color="color">
                    {{ icon }}
                </base-icon>
            </v-col>

            <v-col v-if="title || subtitle" :cols="callout ? 9 : 12">
                <base-title :title="title" class="text-uppercase" space="1">
                    <template v-slot:append-heading>
                        <slot name="append-title" />
                    </template>
                </base-title>

                <base-divider :color="color" space="3" />

                <base-subtitle v-if="subtitle" :title="subtitle" space="1" />

                <base-body
                    v-if="text || $slots.default"
                    :text="text"
                    :space="bodySpace"
                >
                    <slot />
                </base-body>
            </v-col>

            <v-col v-if="callout" cols="2">
                <div
                    class="display-3 grey--text text--lighten-4 font-weight-bold pr-8"
                    v-text="callout"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// Mixins
import Heading from '@/mixins/heading'

export default {
    name: `BaseInfoCard`,

    mixins: [Heading],

    props: {
        dark: Boolean,
        bodySpace: {
            type: [Number, String],
            default: 6
        },
        callout: String,
        color: {
            type: String,
            default: `primary`
        },
        icon: String,
        subtitle: String,
        text: String,
        title: String
    }
}
</script>
