<template>
    <v-text-field
        ref="field"
        outlined
        :data-cy="dataCy"
        v-bind="$attrs"
        v-on="$listeners"
    />
</template>

<script>
import { mdiAlertCircle } from '@mdi/js'
export default {
    name: `BaseTextField`,

    props: {
        dataCy: {
            type: String,
            default: ``
        }
    },

    data() {
        return {
            mdiAlertCircle,
            errorFlag: false
        }
    }
}
</script>
