<template>
    <v-textarea
        ref="field"
        outlined
        :data-cy="dataCy"
        v-bind="{
            rows: 6,
            ...$attrs
        }"
        v-on="$listeners"
    />
</template>

<script>
export default {
    name: `BaseTextarea`,
    props: {
        dataCy: {
            type: String,
            default: ``
        }
    }
}
</script>
