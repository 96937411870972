import { getInstance } from './authWrapper'
export const authGuard = (to, from, next) => {
    // if the route is public continue to the route
    if (to.meta.isPublic) return next()
    const authService = getInstance()
    const fn = async () => {
        if (
            authService &&
            (await authService.getTokenSilently({ forceLoginOnError: true })) &&
            authService.isAuthenticated
        ) {
            // check if route needs any role
            if (
                to.meta &&
                to.meta.anyRole &&
                !authService.anyRole(to.meta.anyRole)
            ) {
                return next({ name: '401' })
            }

            // check if route needs all roles
            if (
                to.meta &&
                to.meta.allRoles &&
                !authService.allRoles(to.meta.allRoles)
            ) {
                return next({ name: '401' })
            }

            return next()
        }
        authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } })
        return next(false)
    }
    if (!authService.loading) {
        return fn()
    }
    authService.$watch('loading', (loading) => {
        if (loading === false) {
            return fn()
        }
        return next(false)
    })
}
