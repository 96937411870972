<script>
import problem from '@/components/recycle_stepper/steps/problem.vue'
import contact from '@/components/recycle_stepper/steps/contact.vue'
import confirm from '@/components/recycle_stepper/steps/confirm.vue'
import downloadLabel from '@/components/recycle_stepper/steps/download_label.vue'
import RecycleFlag from '@/components/recycle_stepper/recycle_flag.vue'
import { rolesMap } from '@/config'
import { postCreateRma } from '@/api'
import { trimAll } from '@/utils'
export default {
    metaInfo: { title: `Recycle Unit` },
    name: `RecycleUnit`,
    components: {
        RecycleFlag,
        problem,
        contact,

        confirm,
        downloadLabel
    },

    beforeRouteLeave(to, from, next) {
        if (!this.newRmaResponse.rmaNumber) {
            const answer = window.confirm(`Do you really want to leave? You have unsaved changes..`)
            if (answer) {
                next()
            } else {
                next(false)
            }
        } else {
            next()
        }
    },

    props: {
        serial: { type: String, default: `` }
    },
    data() {
        return {
            statesLoading: false,
            citiesLoading: false,
            submissionError: ``,
            newRmaResponse: {
                rmaNumber: ``
            },
            problemData: {},
            packingData: {},
            contactData: {
                firstName: ``,
                lastName: ``,
                email: ``,
                phone: ``,
                company: ``,
                phoneCountryCode: ``
            },
            addressData: {
                city: ``,
                country: ``,
                line1: ``,
                line2: ``,
                postalCode: ``,
                state: ``
            },
            unitData: {},
            attachmentsData: [],
            overrideData: {},
            postingRma: false,
            currentStep: 1,
            stepMap: {}
        }
    },

    computed: {
        userStoredAddress() {
            if (this.$auth.user.address && this.$auth.user.address.shipping) {
                return {
                    city: this.$auth.user.address.shipping.city,
                    country: this.$auth.user.address.shipping.country,
                    line1: this.$auth.user.address.shipping.line1,
                    line2: this.$auth.user.address.shipping.line2,
                    postalCode: this.$auth.user.address.shipping.postalCode,
                    state: this.$auth.user.address.shipping.state
                }
            } else return {}
        },
        userStoredContact() {
            if (this.$auth.user) {
                return {
                    company: this.$auth.user.company,
                    email: this.$auth.user.email,
                    firstName: this.$auth.user.firstName,
                    lastName: this.$auth.user.lastName,
                    phone: this.$auth.user.phone,
                    phoneCountryCode: this.$auth.user.phoneCountryCode
                }
            } else return {}
        },
        steps() {
            const steps = [
                { title: `Consent`, id: `problem` },
                { title: `Contact Info`, id: `contact` },
                { title: `Confirm`, id: `confirm` },
                { title: `Download Label`, id: `downloadLabel` }
            ]

            return steps.map((el, i) => {
                el.step = i + 1
                return el
            })
        },
        international() {
            return this.addressData.country && this.addressData.country !== `US`
        },
        countries() {
            return this.$store.getters[`location/getCachedCountries`]
        },

        cities() {
            return this.$store.getters[`location/getCachedCities`]({
                countryCode: this.addressData.country,
                stateCode: this.addressData.state
            })
        },
        stateProvinces() {
            return this.$store.getters[`location/getCachedStates`]({
                countryCode: this.addressData.country
            })
        },
        payload() {
            const address = {
                line1: this.addressData.line1,
                line2: this.addressData.line2,
                city: this.addressData.city,
                state: this.addressData.state,
                country: this.addressData.country,
                postalCode: this.addressData.postalCode
            }
            const contact = {
                firstName: this.contactData.firstName,
                lastName: this.contactData.lastName,
                email: this.contactData.email,
                phone: this.contactData.phone,
                company: this.contactData.company,
                position: this.contactData.position
            }

            const packaging = {
                havePackaging: true,
                forgoShipping: false,
                requestBox: false
            }

            const problemInput = {
                rmaType: `RECYCLE`,
                primaryFault: `RECYCLE`,
                problem: `RECYCLE`,
                initialNotes: `RECYCLE`,
                permissions: this.problemData.permissions,
                acc: this.problemData.acc
            }

            return {
                serial: this.unitData.serial,
                rmaInput: {
                    ...problemInput,
                    attachments: this.attachmentsData,
                    userId: this.$auth.user.sub
                },
                address,
                contact,
                packaging
            }
        },

        isDev() {
            return process.env.NODE_ENV === `development`
        },

        isApo() {
            const stateObject = this.$store.getters[`location/getCachedStates`]({
                countryCode: this.addressData.country
            }).find((e) => e.stateCode === this.addressData.state) || {
                apo: false
            }
            return !!stateObject.apo
        }
    },
    watch: {
        unitData: {
            immediate: true,
            handler(v) {
                // console.log(v)
            }
        }
    },

    methods: {
        testUnitData(e) {
            console.log(e)
        },
        scrollToTop() {
            window.scroll({
                top: 0,
                left: 0
            })
        },
        editStep(editStep) {
            const s = this.steps.find((el) => el.id === editStep)
            this.currentStep = s.step
        },
        continueFrom(currentStep) {
            const s = this.steps.find((el) => el.id === currentStep)
            this.currentStep = s.step + 1
        },
        reverseFrom(currentStep) {
            const s = this.steps.find((el) => el.id === currentStep)
            this.currentStep = s.step - 1
        },
        getStep(currentStep) {
            const s = this.steps.find((el) => el.id === currentStep)
            return s.step
        },
        async getCities(event) {
            this.citiesLoading = true
            try {
                const [countryCode, stateCode] = event
                await this.$store.dispatch(`location/cacheCities`, {
                    countryCode,
                    stateCode
                })
            } catch {
                this.citiesLoading = false
            } finally {
                this.citiesLoading = false
            }
        },
        async getStates(countryCode) {
            this.statesLoading = true
            try {
                await this.$store.dispatch(`location/cacheStates`, {
                    countryCode
                })
                this.statesLoading = false
            } catch {
                this.statesLoading = false
            }
        },
        async resetForm() {
            this.$refs.problem.resetForm()
            this.$refs.contact.resetForm()
            this.$refs.confirm.resetForm()
            this.attachments = []
            this.newRmaResponse = {
                uid: ``
            }

            await this.$nextTick()
            this.currentStep = 1
        },
        downloadLabel(url) {
            window.open(url)
        },
        async createRma() {
            this.postingRma = true
            this.submissionError = ``

            try {
                const { data } = await postCreateRma({
                    payload: trimAll(this.payload)
                })

                this.newRmaResponse = data
                this.currentStep = this.steps.length
            } catch (e) {
                console.error(`API Error Creating RMA:` + e.message)
                this.submissionError = `Error Creating RMA: Please Confirm you info and try again. If the problem persists, reach out to tech support`
            } finally {
                this.postingRma = false
            }
        }
    }
}
</script>

<template>
    <div>
        <v-stepper v-model="currentStep">
            <v-stepper-header>
                <template v-for="(step, i) in steps">
                    <v-stepper-step :key="step.title" :editable="isDev" :complete="currentStep > i + 1" :step="i + 1">
                        {{ step.title }}
                    </v-stepper-step>

                    <v-divider v-if="i !== steps.length - 1" :key="i"></v-divider>
                </template>
            </v-stepper-header>

            <recycle-flag />

            <v-stepper-items>
                <v-stepper-content :step="getStep('problem')">
                    <problem
                        ref="problem"
                        :serial="serial"
                        :user-email="$auth.user.sub"
                        @problemInput="problemData = $event"
                        @unitInput="unitData = $event"
                        @attachmentsInput="attachmentsData = $event"
                        @continue="continueFrom('problem')"
                    />
                </v-stepper-content>

                <v-stepper-content :step="getStep('contact')">
                    <contact
                        ref="contact"
                        :countries="countries"
                        :state-provinces="stateProvinces"
                        :cities="cities"
                        :apo="isApo"
                        :user-stored-address="userStoredAddress"
                        :user-stored-contact="userStoredContact"
                        :states-loading="statesLoading"
                        :cities-loading="citiesLoading"
                        @stateUpdated="getCities"
                        @countryUpdated="getStates"
                        @contactInput="Object.assign(contactData, $event)"
                        @addressInput="Object.assign(addressData, $event)"
                        @continue="continueFrom('contact')"
                        @back="reverseFrom('contact')"
                    />
                </v-stepper-content>

                <v-stepper-content :step="getStep('confirm')">
                    <confirm
                        ref="confirm"
                        :problem-data="problemData"
                        :packing-data="packingData"
                        :contact-data="contactData"
                        :address-data="addressData"
                        :unit-data="unitData"
                        :override-data="overrideData"
                        :attachments-data="attachmentsData"
                        :submission-error="submissionError"
                        :posting-rma="postingRma"
                        @editPage="editStep"
                        @submitRma="createRma"
                        @back="reverseFrom('confirm')"
                    />
                </v-stepper-content>
                <v-stepper-content :step="getStep('downloadLabel')">
                    <download-label
                        :key="newRmaResponse.rmaNumber"
                        :user-input="{
                            problemData,
                            packingData,
                            contactData,
                            addressData,
                            unitData,
                            attachmentsData
                        }"
                        :create-rma-response="newRmaResponse"
                        @createNewRma="resetForm"
                        @downloadLabel="downloadLabel"
                    />
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </div>
</template>
