export const trimAll = (obj) => {
    Object.keys(obj).map((key) => {
        if (typeof obj[key] === `string`) {
            obj[key] = obj[key].trim()
        } else if (obj[key]) {
            trimAll(obj[key])
        }
    })

    return obj
}
