<template>
    <v-container class="recycle-stepper-form">
        <v-row align="start">
            <v-col cols="12" sm="12">
                <base-info-card
                    v-if="validDownloadLink"
                    title="Your Recycle request has been processed"
                    color="primary"
                >
                    <base-btn
                        :disabled="!validDownloadLink"
                        data-cy="btn-download-label"
                        color="primary"
                        :block="$vuetify.breakpoint.smAndDown"
                        :class="
                            !$vuetify.breakpoint.smAndDown
                                ? 'mr-10 mb-5'
                                : 'mt-1'
                        "
                        outlined
                        @click="downloadLabel"
                    >
                        {{ downloadText }}
                    </base-btn>
                    <div v-if="validDownloadLink" class="mb-2">
                        A copy of this download link has also been sent to your
                        email.
                    </div>
                </base-info-card>

                <base-info-card
                    v-if="createRmaResponse && createRmaResponse.rmaNumber"
                    data-cy="rma-created-headline"
                    :title="`Recycle# ${createRmaResponse.rmaNumber} Summary`"
                    color="primary"
                >
                    <base-card-summary
                        tile
                        flat
                        :data-map="summaryMap"
                        :all-data="userInput"
                    >
                        <template v-slot:rmaNumber>
                            {{ createRmaResponse.rmaNumber }}
                        </template>
                        <template v-slot:unitData.endDeviceWarranty="{ item }">
                            {{ formatDateShort(item) }}
                        </template>
                    </base-card-summary>
                </base-info-card>
                <base-info-card
                    v-else
                    title="Recycle Request has not been created"
                    text="If you feel you reached this page in error, please reach out to support"
                    color="primary"
                >
                </base-info-card>
                <!-- <base-btn
                    color="secondary"
                    data-cy="btn-create-another-rma"
                    small
                    :block="$vuetify.breakpoint.smAndDown"
                    :class="!$vuetify.breakpoint.smAndDown ? 'mr-10' : 'mt-1'"
                    outlined
                    @click="$emit('createNewRma')"
                >
                    Need to Create a recycle another Unit?
                </base-btn> -->
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
// TODO move the link to rma history out of if statement
import { formatDateShort } from '@/utils'
export default {
    name: `DownloadLabelStep`,
    props: {
        createRmaResponse: {
            type: Object,
            default: () => ({
                label: ``,
                commercialInvoice: ``,
                rmaNumber: ``
            })
        },
        userInput: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            summaryMap: [
                {
                    mainTitle: ``,
                    mainItems: [
                        {
                            subTitle: `Unit Details`,
                            subItems: [
                                {
                                    title: `Serial #`,
                                    path: `unitData.serial`
                                },
                                {
                                    title: `Model`,
                                    path: `unitData.model`
                                }
                            ]
                        },
                        {
                            subTitle: `Contact Info`,
                            subItems: [
                                {
                                    title: `First Name`,
                                    path: `contactData.firstName`
                                },
                                {
                                    title: `Last Name`,
                                    path: `contactData.lastName`
                                },
                                {
                                    title: `Email`,
                                    path: `contactData.email`
                                },
                                {
                                    title: `Phone`,
                                    path: `contactData.phone`
                                },
                                {
                                    title: `Orginization`,
                                    path: `contactData.company`
                                },
                                {
                                    title: `Position`,
                                    path: `contactData.position`
                                }
                            ]
                        },
                        {
                            subTitle: `Address`,
                            subItems: [
                                {
                                    title: `Address 1`,
                                    path: `addressData.line1`
                                },
                                {
                                    title: `Address 2`,
                                    path: `addressData.line2`
                                },
                                {
                                    title: `City`,
                                    path: `addressData.city`
                                },
                                {
                                    title: `State`,
                                    path: `addressData.state`
                                },
                                {
                                    title: `Country`,
                                    path: `addressData.country`
                                },
                                {
                                    title: `Zip/Postal Code`,
                                    path: `addressData.postalCode`
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    },
    computed: {
        downloadText() {
            let text = `Download Shipping Label`
            if (this.createRmaResponse.commercialInvoice) {
                text += ` & Commercial Invoice`
            }
            return text
        },
        validDownloadLink() {
            return (
                !!this.createRmaResponse.rmaNumber &&
                !!this.createRmaResponse.label
            )
        }
    },
    methods: {
        formatDateShort,

        async downloadLabel() {
            this.$emit(`downloadLabel`, this.createRmaResponse.label)
            if (this.createRmaResponse.commercialInvoice) {
                this.$emit(
                    `downloadLabel`,
                    this.createRmaResponse.commercialInvoice
                )
            }
        }
    }
}
</script>
