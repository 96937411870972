<template>
    <gusa-container container-class="pt-0">
        <div style="display: flex; flex-direction: row">
            <img
                style="width: 130px; margin-bottom: 1%"
                src="../../../assets/logos/getac-logo.svg"
            />
            <div class="text-h7 nav-font pl-3" style="margin-top: 1.3%"
                >CREATE RMA</div
            >
        </div>
        <v-divider style="margin-bottom: 1%; border-color: gray" />
        <v-container class="download-label-container">
            <base-info-card
                v-if="!!!rmaNumber"
                class="my-2"
                title="RMA has not been created"
                text="If you feel you reached this page in error, please reach out to support"
                color="primary"
            >
            </base-info-card>
            <div v-else>
                <base-info-card
                    data-cy="success-rma-created"
                    title="Your RMA has been created"
                    color="primary"
                    class="my-2"
                />

                <base-btn
                    v-if="!!downloadLabelUrl"
                    class="my-2"
                    data-cy="btn-download-label"
                    color="primary"
                    :block="$vuetify.breakpoint.smAndDown"
                    :class="!$vuetify.breakpoint.smAndDown ? 'mr-1' : 'mt-1'"
                    outlined
                    @click="downloadLabel"
                >
                    Download Shipping Label
                </base-btn>
                <base-body v-if="!!downloadLabelUrl" class="my-2" space="3">
                    A copy of this download link has also been sent to your
                    email.
                </base-body>
                <base-btn
                    v-if="!!commercialInvoiceUrl"
                    class="my-2"
                    data-cy="btn-download-commercial-invoice"
                    color="primary"
                    :block="$vuetify.breakpoint.smAndDown"
                    :class="!$vuetify.breakpoint.smAndDown ? 'mr-10' : 'mt-1'"
                    outlined
                    @click="downloadCommercialInvoice"
                >
                    Download Commercial Invoice
                </base-btn>
                <base-body
                    v-if="advanceExchange"
                    data-cy="advance-exchange-shipment"
                    space="3"
                >
                    A new device will be shipped to you with a return label to
                    send your current device back.
                </base-body>
                <base-body
                    v-if="
                        !downloadLabelUrl &&
                        !commercialInvoiceUrl &&
                        shippingError
                    "
                    data-cy="error-on-shipment"
                    space="3"
                >
                    There was a error during shipping label creation. If you
                    don't receive a label via email within 24 hours: please
                    contact
                    <a
                        :href="
                            'mailto:getacsupport_us@getac.com?subject=Error during label creation for RMA# ' +
                            rmaNumber
                        "
                        >Getac Service</a
                    >
                    with your rma number, and a label will be provided.
                </base-body>
                <base-body
                    v-if="
                        !downloadLabelUrl &&
                        !commercialInvoiceUrl &&
                        !shippingError
                    "
                    data-cy="international-email"
                    space="3"
                >
                    If this is a international RMA, please contact
                    <a
                        :href="
                            'mailto:getacsupport_us@getac.com?subject=I need a international label for RMA# ' +
                            rmaNumber
                        "
                        >Getac Service</a
                    >
                    with your rma number, and a label will be provided.
                </base-body>
            </div>

            <base-body class="my-2" space="3">
                After your service request is complete, you can access a parts
                replaced repair history by clicking on
                <router-link to="/your-account/rma-history">
                    Your RMAs</router-link
                >
                then clicking
                <base-btn
                    style="cursor: default"
                    small
                    outlined
                    color="blue darken-1"
                    class="mx-1"
                >
                    Repair Summary</base-btn
                >
                from inside RMA details window.
            </base-body>

            <base-info-card
                v-if="!!rmaNumber"
                data-cy="rma-created-headline"
                :title="`RMA# ${createRmaResponse.rmaNumber} Summary`"
                color="primary"
            >
                <base-card-summary
                    class="base-summary"
                    tile
                    flat
                    :data-map="summaryMap"
                    :all-data="userInput"
                >
                    <template v-slot:rmaNumber>
                        {{ createRmaResponse.rmaNumber }}
                    </template>
                    <template v-slot:unitData.endDeviceWarranty="{ item }">
                        {{ formatDateShort(item) }}
                    </template>
                </base-card-summary>
            </base-info-card>

            <v-col>
                <base-btn
                    color="secondary"
                    data-cy="btn-create-another-rma"
                    small
                    :block="$vuetify.breakpoint.smAndDown"
                    :class="!$vuetify.breakpoint.smAndDown ? 'mr-10' : 'mt-1'"
                    outlined
                    @click="$emit('createNewRma')"
                >
                    Need to Create a new Service Request?
                </base-btn>
            </v-col>
        </v-container>
    </gusa-container>
</template>

<script>
import { formatDateShort } from '@/utils'
import { poDownload } from '@/api'
import RepairTypeMixin from '@/mixins/repair_type'
import GusaContainer from '@/layouts/home/container.vue'

export default {
    name: `DownloadLabelStep`,
    components: {
        GusaContainer
    },
    mixins: [RepairTypeMixin],
    props: {
        createRmaResponse: {
            type: Object,
            default: () => ({
                shippingError: false,
                label: ``,
                commercialInvoice: ``,
                rmaNumber: ``
            })
        },
        international: {
            type: Boolean,
            default: false
        },
        userInput: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            summaryMap: [
                {
                    mainTitle: `RMA Info`,
                    mainItems: [
                        {
                            subTitle: ``,
                            subItems: [
                                {
                                    title: `RMA #`,
                                    path: `rmaNumber`,
                                    override: true
                                },
                                {
                                    title: `Primary Fault`,
                                    path: `problemData.primaryFault`
                                },
                                {
                                    title: `Problem`,
                                    path: `problemData.problem`
                                },
                                {
                                    title: `User Notes`,
                                    path: `problemData.initialNotes`
                                },
                                {
                                    title: `User including Hard Case`,
                                    path: `problemData.acc.hardCase`
                                },
                                {
                                    title: `User including AC Power`,
                                    path: `problemData.acc.acPower`
                                },
                                {
                                    title: `User including battery`,
                                    path: `problemData.acc.battery`
                                },
                                {
                                    title: `User including storage (HDD/SSD)`,
                                    path: `problemData.acc.storage`
                                },
                                {
                                    title: `Windows System Re-Image Approval`,
                                    path: `problemData.permissions.reimage`
                                },
                                {
                                    title: `BIOS Upgrade Approval`,
                                    path: `problemData.permissions.biosFirmware`
                                }
                            ]
                        },
                        {
                            subTitle: `Unit Details`,
                            subItems: [
                                {
                                    title: `Unit Ref ID`,
                                    path: `problemData.custReference`
                                },
                                {
                                    title: `Asset Tag`,
                                    path: `problemData.assetTag`
                                },

                                {
                                    title: `Serial #`,
                                    path: `unitData.serial`
                                },
                                {
                                    title: `Model`,
                                    path: `unitData.model`
                                },
                                {
                                    title: `Warranty Expiration Date`,
                                    path: `unitData.endDeviceWarranty`
                                },
                                {
                                    title: `Warranty Type`,
                                    path: `unitData.warrantyType`
                                },
                                {
                                    title: `Windows User Name`,
                                    path: `problemData.permissions.userId`
                                }
                            ]
                        }
                    ]
                },
                {
                    mainTitle: `Contact Info`,
                    mainItems: [
                        {
                            subTitle: ``,
                            subItems: [
                                {
                                    title: `First Name`,
                                    path: `contactData.firstName`
                                },
                                {
                                    title: `Last Name`,
                                    path: `contactData.lastName`
                                },
                                {
                                    title: `Email`,
                                    path: `contactData.email`
                                },
                                {
                                    title: `Phone`,
                                    path: `contactData.phone`
                                },
                                {
                                    title: `Orginization`,
                                    path: `contactData.company`
                                },
                                {
                                    title: `Position`,
                                    path: `contactData.position`
                                }
                            ]
                        },
                        {
                            subTitle: `Address`,
                            subItems: [
                                {
                                    title: `Address 1`,
                                    path: `addressData.line1`
                                },
                                {
                                    title: `Address 2`,
                                    path: `addressData.line2`
                                },
                                {
                                    title: `City`,
                                    path: `addressData.city`
                                },
                                {
                                    title: `State`,
                                    path: `addressData.state`
                                },
                                {
                                    title: `Country`,
                                    path: `addressData.country`
                                },
                                {
                                    title: `Zip/Postal Code`,
                                    path: `addressData.postalCode`
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    },
    computed: {
        downloadText() {
            let text = `Download Shipping Label`
            if (this.createRmaResponse.commercialInvoice) {
                text += ` & Commercial Invoice`
            }
            return text
        },
        shippingError() {
            return !!this.createRmaResponse.shippingError
        },
        rmaNumber() {
            return this.createRmaResponse.rmaNumber
        },
        downloadLabelUrl() {
            return this.createRmaResponse.label
        },
        commercialInvoiceUrl() {
            return this.createRmaResponse.commercialInvoice
        }
    },
    methods: {
        formatDateShort,

        async downloadCommercialInvoice() {
            this.$emit(
                `downloadCommercialInvoice`,
                this.createRmaResponse.commercialInvoice
            )
        },

        async downloadLabel() {
            this.$emit(`downloadLabel`, this.createRmaResponse.label)
        },
        async getPoFile() {
            await poDownload({ filename: this.filename })
        }
    }
}
</script>

<style>
.theme--light.v-application .base-summary .text--primary {
    color: white !important;
}
.download-label-container p {
    color: white !important;
}
</style>
