<template>
    <div>
        <v-overlay :value="overlay" opacity="0.85" class="overflow:hidden;">
            <div class="text-center">
                <v-progress-circular class="ma-3" indeterminate large color="secondary" />
                <br />
                <span>
                    You are currently processing a payment in another window.
                    <br />
                    Please close that window if you would like to make changes.
                </span>
            </div>
        </v-overlay>
        <slot name="activator" :on="on" />
    </div>
</template>

<script>
export default {
    name: 'PaymentDialog',
    props: {
        window: {
            type: Boolean,
            required: false,
            default: false
        },
        url: {
            type: String,
            required: true
        },
        rmaNumber: {
            type: String,
            required: true
        },
        paymentType: {
            type: String,
            required: false,
            default: ``
        }
    },
    data() {
        const self = this
        return {
            on: {
                click: self.openEvent
            },
            overlay: false,
            popupWindow: null
        }
    },
    beforeMount() {
        window.addEventListener('beforeunload', this.closeWindow)
        this.$once('hook:beforeDestroy', () => {
            window.removeEventListener('beforeunload', this.closeWindow)
        })
    },
    methods: {
        closeWindow() {
            if (this.popupWindow) {
                this.popupWindow.close()
            }
        },
        openEvent(e) {
            this.createWindow()
        },
        addPopupListener() {
            this.popupWindow.addEventListener('unload', this.childNavigated)
        },
        childNavigated(event) {
            console.log('happened')
        },
        createLoop() {
            const loop = setInterval(() => {
                if (this.popupWindow.closed) {
                    this.overlay = false
                    clearInterval(loop)
                    // alert(`closed`)
                } else {
                    try {
                        const path = this.popupWindow.location.pathname
                        console.log(path)
                        if (this.paymentType === 'quote') {
                            if (path === '/service-quote-success') {
                                this.$emit('success')
                                this.closeWindow()
                            } else if (path === '/service-quote-canceled') {
                                this.$emit('canceled')
                                this.closeWindow()
                            }
                        } else {
                            if (path === '/success') {
                                console.log(path)
                                this.$emit('success')
                                this.closeWindow()
                            } else if (path === '/canceled') {
                                console.log(path)
                                this.$emit('canceled')
                                this.closeWindow()
                            }
                        }
                    } catch (e) {
                        // ignore not accessible error
                        console.error(e)
                    }
                }
            }, 1000)
        },
        createWindow(w = 400, h = 550) {
            const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX
            const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY

            const width = window.innerWidth
                ? window.innerWidth
                : document.documentElement.clientWidth
                ? document.documentElement.clientWidth
                : screen.width
            const height = window.innerHeight
                ? window.innerHeight
                : document.documentElement.clientHeight
                ? document.documentElement.clientHeight
                : screen.height

            const systemZoom = width / window.screen.availWidth
            const left = (width - w) / 2 / systemZoom + dualScreenLeft
            const top = (height - h) / 2 / systemZoom + dualScreenTop

            this.popupWindow = window.open(
                this.url,
                'popup',
                `width=${w / systemZoom},height=${
                    h / systemZoom
                },top=${top},left=${left},directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no`
            )

            this.overlay = true
            this.createLoop()
        }
    }
}
</script>
