<script>
import get from 'lodash/get'
import PoUploader from '@/components/po_uploader/po_uploader.vue'
import GusaContainer from '@/layouts/home/container.vue'
import { rolesMap } from '@/config'
import { discountCalculation } from '@/mixins/discount'

export default {
    name: `ConfirmstepId`,
    components: { PoUploader, GusaContainer },
    mixins: [discountCalculation('problemData.problemPrice')],
    props: {
        contactData: { type: Object, default: () => {} },
        addressData: { type: Object, default: () => {} },
        packingData: { type: Object, default: () => {} },
        problemData: { type: Object, default: () => {} },
        unitData: { type: Object, default: () => {} },
        overrideData: { type: Object, default: () => {} },
        userHasOverride: Boolean,
        postingRma: Boolean,
        submissionError: {
            default: ``,
            type: String
        }
    },

    data() {
        return {
            isPaymentPO: this.$auth.allRoles(rolesMap.paymentPO),
            showValidationErrors: false,
            confirmData: false,
            formValid: false,
            paymentMethod: this.$auth.allRoles(rolesMap.paymentPO) ? 'PO' : 'PayPal',
            poUpload: '',
            poNumber: '',
            addressHeaders: [
                { path: `addressData.line1`, title: `Address` },
                { path: `addressData.line2`, title: `Address 2` },
                { path: `addressData.city`, title: `City` },
                { path: `addressData.country`, title: `Country` },
                {
                    path: `addressData.state`,
                    title: `State/Province`
                },
                { path: `addressData.postalCode`, title: `Postal Code` }
            ],
            contactHeaders: [
                { path: `contactData.firstName`, title: `First Name` },
                { path: `contactData.lastName`, title: `Last Name` },
                { path: `contactData.company`, title: `Company` },
                { path: `contactData.position`, title: `Position` },
                { path: `contactData.email`, title: `Email` },
                { path: `contactData.phone`, title: `Phone#` }
            ],
            packingHeaders: [
                {
                    path: `packingData.havePackaging`,
                    title: `I have packaging`
                },
                { path: `packingData.requestBox`, title: `I need a box` }
            ],
            unitHeaders: [
                { path: `unitData.serial`, title: `Serial Number` },
                { path: `unitData.model`, title: `Model` }
            ],
            overrideHeaders: [
                { path: `overrideData.tpm`, title: `Destination TPM` },
                { path: `overrideData.rmaType`, title: `RMA Type` }
            ],
            problemHeaders: [
                { path: `problemData.primaryFault`, title: `Selected Problem` },
                { path: `problemData.problem`, title: `Sub Problem` },
                {
                    path: `problemData.shippingWithDevice`,
                    title: `Shipping Items`
                },
                {
                    path: `problemData.permissions.reimage`,
                    title: `Re Image Approval?`
                },
                {
                    path: `problemData.permissions.biosFirmware`,
                    title: `Upgrade BIOS?`
                },
                { path: `problemData.windowsUserName`, title: `Windows UN` },
                { path: `problemData.custReference`, title: `Ref No#` },
                { path: `problemData.assetTag`, title: `Asset Tag` },
                { path: `problemData.initialNotes`, title: `User Notes` }
            ],
            paymentHeaders: [
                {
                    path: `problemData.problemPrice`,
                    title: `Service Request Quote Fee`
                }
            ],
            colMd: `6`,
            title: `Complete`,
            poTitle: 'Enter PO Number',
            poLabel: 'PO #',
            releaseTitle: 'Enter Release Number',
            releaseLabel: 'Release # (Ex: 0116)',
            releaseNumber: ''
        }
    },

    computed: {
        inputData() {
            return {
                contactData: this.contactData,
                addressData: this.addressData,
                packingData: this.packingData,
                problemData: this.problemData,
                unitData: this.unitData,
                overrideData: this.overrideData
            }
        },
        col1() {
            return [
                {
                    data: this.inputData || {},
                    title: `Unit Description`,
                    headers: this.unitHeaders,
                    stepId: `problem`
                },
                {
                    data: this.inputData || {},
                    title: `Problem Description`,
                    headers: this.problemHeaders,
                    stepId: `problem`
                },
                {
                    data: this.inputData || {},
                    title: `Packaging`,
                    headers: this.packingHeaders,
                    stepId: `packing`
                }
            ]
        },
        col2() {
            const col = [
                {
                    data: this.inputData || {},
                    title: `Contact Information`,
                    headers: this.contactHeaders,
                    stepId: `contact`
                },
                {
                    data: this.inputData || {},
                    title: `Shipping Information`,
                    headers: this.addressHeaders,
                    stepId: `contact`
                }
            ]
            if (this.userHasOverride) {
                col.push({
                    data: this.inputData || {},
                    title: `Admin Overrides`,
                    headers: this.overrideHeaders,
                    stepId: `override`
                })
            }
            return col
        },
        isMcElroy() {
            return (this.$auth?.user?.email || '').endsWith('@mcelroy.com')
        },
        unableToSumbitPO() {
            const regex = /^\d{4,5}$/
            return (
                !this.poNumber ||
                !this.poUpload ||
                this.poSubmitted ||
                (this.isMcElroy && !regex.test(this.releaseNumber))
            )
        }
    },

    watch: {
        unitData: {
            immediate: true,
            handler(v) {
                const add = {
                    path: `problemData.permissions.hasDeviceData`,
                    title: `Device has data?`
                }
                if (v.productType === 'VERETOS') {
                    this.problemHeaders.push(add)
                } else {
                    const found = this.problemHeaders.find(
                        (e) => e.path === add.path
                    )
                    if (found) {
                        this.problemHeaders.splice(
                            this.problemHeaders.length - 1,
                            1
                        )
                    }
                }
            }
        }
    },
    methods: {
        async resetForm() {
            this.$refs.form.reset()
            await this.$nextTick()
        },
        editPage(v) {
            this.confirmData = false
            this.$emit(`editPage`, v)
        },
        goBack() {
            this.confirmData = false
            this.$emit(`back`)
        },
        submitFinal() {
            this.submitted = true
            this.$emit(`submitRma`)
        },
        getFileName(file) {
            this.poUpload = file.file
        },
        async proceedPayment(isSave) {
            if (this.paymentMethod === 'PO') {
                const po = this.isMcElroy ? `${this.poNumber}-${this.releaseNumber}` : this.poNumber
                this.$emit(`proceedPayment`, {
                    paymentMethod: this.paymentMethod,
                    customerPO: po,
                    customerPOFileId: this.poUpload,
                    save: isSave
                })
            } else {
                this.$emit(`proceedPayment`, {
                    paymentMethod: this.paymentMethod,
                    save: isSave
                })
            }
        },
        itemValue(data, path) {
            return get(data, path, null)
        },
        validateOnContinue() {
            const result = this.$refs.form.validate()
            if (!this.formValid) this.showValidationErrors = true
            const self = this
            setTimeout(() => {
                self.showValidationErrors = false
            }, 4000)
            return result && (!this.isPaymentPO || !this.unableToSumbitPO)
        },
        async saveQuote() {
            await this.proceedPayment(false) // proceedPayment automatically send to the backend
        }
    }
}
</script>

<template>
    <gusa-container container-class="pt-0">
        <div style="display: flex; flex-direction: row">
            <img style="width: 130px; margin-bottom: 1%" src="../../../assets/logos/getac-logo.svg" />
            <div class="text-h7 nav-font pl-3" style="margin-top: 1.3%">CREATE RMA</div>
        </div>
        <v-divider style="margin-bottom: 1%; border-color: gray" />
        <v-form ref="form" v-model="formValid">
            <div>
                <div>
                    <base-info-card class="mx-0" title="RMA Summary" color="primary" />
                </div>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-card
                            v-for="item in col1"
                            :key="item.title"
                            style="background-color: transparent; border: 1px solid gray; margin-bottom: 35px"
                            elevation="0"
                        >
                            <v-card-title class="text-subtitle-2" style="justify-content: space-between">
                                {{ item.title }}
                                <base-btn
                                    :data-cy="`btn-edit${item.title}`"
                                    class="edit-button"
                                    color="primary"
                                    text
                                    small
                                    @click="editPage(item.stepId)"
                                >
                                    Edit
                                </base-btn>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-list style="background-color: transparent" dense>
                                <span v-for="header in item.headers" :key="header.k">
                                    <v-list-item v-if="itemValue(item.data, header.path) !== null">
                                        <v-list-item-content class="text-body-2">
                                            {{ header.title }}:
                                        </v-list-item-content>
                                        <v-list-item-content class="align-end text-body-2 field-values">
                                            {{ itemValue(item.data, header.path) }}
                                        </v-list-item-content>
                                    </v-list-item>
                                </span>
                            </v-list>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-card
                            v-for="item in col2"
                            :key="item.title"
                            style="background-color: transparent; border: 1px solid gray; margin-bottom: 35px"
                            elevation="0"
                        >
                            <v-card-title class="text-subtitle-2" style="justify-content: space-between">
                                {{ item.title }}
                                <base-btn
                                    :data-cy="`btn-edit${item.title}`"
                                    color="primary"
                                    class="edit-button"
                                    text
                                    small
                                    @click="editPage(item.stepId)"
                                >
                                    Edit
                                </base-btn>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-list style="background-color: transparent" dense>
                                <span v-for="header in item.headers" :key="header.k">
                                    <v-list-item v-if="itemValue(item.data, header.path) !== null">
                                        <v-list-item-content
                                            :data-cy="`info-card-key-${header.title}`"
                                            class="text-body-2"
                                        >
                                            {{ header.title }}:
                                        </v-list-item-content>
                                        <v-list-item-content
                                            :data-cy="`info-card-key-value-${header.title}`"
                                            class="align-end text-body-2 field-values"
                                        >
                                            {{ itemValue(item.data, header.path) }}
                                        </v-list-item-content>
                                    </v-list-item>
                                </span>
                            </v-list>
                        </v-card>

                        <v-card
                            v-if="!unitData.iw"
                            elevation="0"
                            style="background-color: transparent; border: 1px solid gray; margin-bottom: 35px"
                        >
                            <v-card-title class="text-subtitle-2"> Payment Information </v-card-title>
                            <v-divider></v-divider>
                            <v-list dense style="background-color: transparent">
                                <span v-for="(header, index) in paymentHeaders" :key="index">
                                    <v-list-item>
                                        <v-list-item-content
                                            :data-cy="`info-card-key-${header.title}`"
                                            class="text-body-2"
                                        >
                                            {{ header.title }}:
                                        </v-list-item-content>
                                        <v-list-item-content
                                            :data-cy="`info-card-key-value-${header.title}`"
                                            class="align-end text-body-2 field-values"
                                        >
                                            ${{ subtotalAfterDiscount }}
                                        </v-list-item-content>
                                    </v-list-item>
                                </span>
                                <span>
                                    <v-list-item>
                                        <v-list-item-content
                                            :data-cy="`info-card-key-payment-method`"
                                            class="text-body-2"
                                        >
                                            Payment Method:
                                        </v-list-item-content>
                                        <v-list-item-content
                                            :data-cy="`info-card-key-value-payment-method`"
                                            class="align-end text-body-2 field-values"
                                        >
                                            <v-radio-group v-model="paymentMethod" dense row>
                                                <v-radio
                                                    v-if="!isPaymentPO"
                                                    label="Credit Card"
                                                    value="PayPal"
                                                ></v-radio>
                                                <v-radio v-if="isPaymentPO" label="PO" value="PO"></v-radio>
                                            </v-radio-group>
                                        </v-list-item-content>
                                    </v-list-item>
                                </span>
                                <span v-if="paymentMethod === 'PO'">
                                    <v-list-item>
                                        <v-list-item-content
                                            :data-cy="`info-card-key-value-payment-method`"
                                            class="align-end text-body-2 field-values"
                                        >
                                            <base-info-card :title="poTitle" color="primary">
                                                <v-text-field
                                                    v-model="poNumber"
                                                    outlined
                                                    dense
                                                    :label="poLabel"
                                                ></v-text-field>
                                            </base-info-card>
                                            <base-info-card v-if="isMcElroy" :title="releaseTitle" color="primary">
                                                <v-text-field
                                                    v-model="releaseNumber"
                                                    outlined
                                                    dark
                                                    :label="releaseLabel"
                                                    dense
                                                ></v-text-field>
                                            </base-info-card>
                                        </v-list-item-content>
                                    </v-list-item>
                                </span>
                                <span v-if="paymentMethod === 'PO'">
                                    <v-list-item style="margin-top: -35px">
                                        <v-list-item-content
                                            :data-cy="`info-card-key-value-payment-method`"
                                            class="align-end text-body-2 field-values"
                                        >
                                            <po-uploader @fileId="getFileName($event)"></po-uploader>
                                        </v-list-item-content>
                                    </v-list-item>
                                </span>
                            </v-list>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row class="mb-0 pb-0">
                    <v-col
                        cols="12"
                        class="mb-0 pb-0"
                        :class="`d-flex ${$vuetify.breakpoint.smAndDown ? 'flex-wrap' : ''} justify-end`"
                    >
                        <div>
                            <base-info-card class="mb-0 pb-0" title="Confirm Your Information" color="primary">
                                <v-checkbox
                                    v-model="confirmData"
                                    data-cy="form-confirm-rma-details"
                                    :rules="[(v) => v === true]"
                                    hide-details
                                    label="I confirm the above information is accurate"
                                />
                            </base-info-card>
                        </div>
                    </v-col>
                </v-row>
                <v-alert v-if="submissionError || showValidationErrors" block :icon="false" type="error" dismissible>
                    {{ showValidationErrors ? 'Missing Required Fields!' : submissionError || 'Please try again.' }}
                </v-alert>
                <div class="mb-4 justify-end" :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : 'd-flex'">
                    <v-row align-content="end">
                        <v-col>
                            <base-btn
                                :block="$vuetify.breakpoint.smAndDown"
                                :class="!$vuetify.breakpoint.smAndDown ? 'mr-2' : 'mt-1 mb-3'"
                                color="primary"
                                outlined
                                :disabled="postingRma"
                                @click="goBack"
                            >
                                Back
                            </base-btn>
                        </v-col>
                    </v-row>

                    <v-spacer />

                    <slot
                        name="buttons"
                        :payment-method="paymentMethod"
                        :form-valid="formValid"
                        :po-number="poNumber"
                        :po-upload="poUpload"
                        :validate="validateOnContinue"
                        :unable-to-submit-po="unableToSumbitPO"
                        :release-number="releaseNumber"
                    />
                </div>
            </div>
        </v-form>
    </gusa-container>
</template>

<style scoped>
.field-values {
    font-weight: 500;
}
</style>

<style>
.edit-button {
    min-width: 0px !important;
}
</style>
