<template>
    <v-card :dark="darkMode" v-bind="$attrs" :style="cardStyle" data-cy="unit-card">
        <div class="d-flex">
            <div>
                <!-- <v-card-title class="headline">{{ serial }}</v-card-title>

                <v-card-subtitle>{{ model }}</v-card-subtitle> -->

                <v-card-text>
                    <h1 style="font-size: 28px; letter-spacing: -0.08rem">{{ serial }}</h1>
                    <p class="text-h7 nav-font pl-3" style="margin: 0; margin-left: -10px; opacity: 0.7">{{ model }}</p>

                    <div v-if="warrantyType" style="margin-top: 10px">
                        <h1
                            v-if="!rmaExists"
                            style="
                                font-size: 15px;
                                letter-spacing: -0.01rem;
                                text-transform: uppercase;
                                margin-bottom: 10px;
                            "
                            >Your unit is
                            <br />
                            <span :style="iwTextColor"> {{ iwText }}</span></h1
                        >
                        <h1
                            v-if="rmaExists"
                            style="
                                font-size: 15px;
                                letter-spacing: -0.01rem;
                                text-transform: uppercase;
                                margin-bottom: 10px;
                            "
                        >
                            RMA Already Exists For This Unit</h1
                        >
                        <p
                            class="text-h7 nav-font pl-3"
                            style="
                                margin: 0;
                                margin-left: -10px;
                                opacity: 0.7;
                                font-weight: 300;
                                font-size: 14px !important;
                            "
                            >WARRANTY EXP</p
                        >
                        <h1 style="font-size: 20px; letter-spacing: -0.03rem">{{ formatDate(endDeviceWarranty) }}</h1>
                        <p
                            class="text-h7 nav-font pl-3"
                            style="
                                margin: 0;
                                margin-left: -10px;
                                margin-top: 5px;
                                opacity: 0.7;
                                font-weight: 300;
                                font-size: 14px !important;
                            "
                            >WARRANTY TYPE</p
                        >
                        <h1 style="font-size: 20px; letter-spacing: -0.01rem">{{
                            advanceExchange ? 'Advance Exchange' : warrantyType
                        }}</h1>
                        <br />
                    </div>
                    <h1
                        v-if="allowOow"
                        style="
                            font-size: 15px;
                            letter-spacing: -0.01rem;
                            text-transform: uppercase;
                            margin-bottom: 10px;
                        "
                    >
                        <v-icon>{{ mdiAlertCircle }}</v-icon>
                        Repairs may require payment upon diagnosis/inspection
                    </h1>
                    <h1
                        v-if="allowOow"
                        style="
                            font-size: 15px;
                            letter-spacing: -0.01rem;
                            text-transform: uppercase;
                            margin-bottom: 10px;
                        "
                    >
                        <v-icon>{{ mdiAlertCircle }}</v-icon>
                        An override has been applied to allow you to submit this RMA without prepayment
                    </h1>
                </v-card-text>
            </div>
            <v-avatar class="ma-2 mr-8" size="235" rounded>
                <v-img contain :src="unitImg"></v-img>
            </v-avatar>
        </div>
    </v-card>
</template>
<script>
import { formatDate } from '@/utils'
import { mdiAlertCircle } from '@mdi/js'
import RepairTypeMixin from '@/mixins/repair_type'

export default {
    name: `UnitCard`,
    mixins: [RepairTypeMixin],
    props: {
        warrantyType: {
            type: String,
            default: ``
        },
        serial: {
            type: String,
            default: `NO-SERIAL-PROVIDED`
        },
        model: {
            type: String,
            default: `NO-MODEL-PROVIDED`
        },
        modelFamily: {
            type: String,
            default: `NO-MODEL-FAMILY-PROVIDED`
        },
        allowOow: {
            type: Boolean,
            required: true
        },
        iw: {
            type: Boolean,
            required: true
        },
        rmaExists: {
            type: Boolean,
            required: true
        },
        endDeviceWarranty: {
            type: String,
            default: `NO-EXP-PROVIDED`
        },
        isEOS: {
            type: Boolean,
            default: false
        },
        isSpecialModel: {
            type: Boolean,
            default: false
        },
        isRecyclePage: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            mdiAlertCircle
        }
    },
    computed: {
        unitImg() {
            // const name = this.model.split(`-`)
            // const fileName = (`` + name[0]).toLowerCase()
            const fileName = (`` + this.modelFamily).toLowerCase()

            return process.env.VUE_APP_PUBLIC_ASSETS_URL + `/images/units/${fileName}.png`
        },
        darkMode() {
            if (this.cardColor === `yellow`) return false
            else return true
        },
        cardStyle() {
            if ((this.iw || this.allowOow) && !this.rmaExists && this.warrantyType)
                return `
                    background-color: ${
                        this.isRecyclePage ? '#4caf50' : 'transparent !important; border: 1px solid green'
                    }`
            if (!this.serial || !this.model || !this.endDeviceWarranty)
                return `
                    background-color: ${
                        this.isRecyclePage ? '#ff9797' : 'transparent !important; border: 1px solid red'
                    }`
            if (this.rmaExists)
                return `
                    background-color: ${
                        this.isRecyclePage
                            ? '#CDBE82; color: white !important'
                            : 'transparent !important; border: 1px solid yellow; color: white !important'
                    }`
            if (!this.warrantyType)
                return `
                    background-color: ${this.isRecyclePage ? 'grey' : 'transparent !important; border: 1px solid grey'}`
            return `
                    background-color: ${
                        this.isRecyclePage ? '#ff9797' : 'transparent !important; border: 1px solid red'
                    }`
        },
        cardColor() {
            if ((this.iw || this.allowOow) && !this.rmaExists && this.warrantyType) return `green`
            if (!this.serial || !this.model || !this.endDeviceWarranty) return `red`
            if (this.rmaExists) return `yellow`
            if (!this.warrantyType) return `grey`
            return `red`
        },
        iwText() {
            if (!this.warrantyType) return `Your unit is missing data, please contact support`
            return this.isSpecialModel
                ? 'Special Service Process'
                : this.iw
                ? `Covered under Warranty`
                : this.isEOS
                ? 'End of Service'
                : `Out of Warranty`
        },
        iwTextColor() {
            if (!this.warrantyType) return ``
            return this.iw ? `color: lightgreen` : `color:#db5120`
        },
        inRmaText() {
            return this.rmaExists ? `Your unit has an active RMA` : ``
        }
    },

    methods: {
        recycle() {
            this.$router.push({
                name: `recycle-unit`,
                params: {
                    serial: this.serial || ``
                }
            })
        },

        formatDate
    }
}
</script>
