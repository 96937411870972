<template>
    <div class="d-flex flex-wrap">
        <router-link
            v-for="(r, i) in items"
            :key="r.route"
            v-slot="{ navigate, isActive }"
            :to="r.route"
            classes
        >
            <span>
                <span
                    class="mr-2 font-weight-medium accent--text"
                    :class="isActive ? 'selected-route' : ''"
                >
                    <a @click="navigate">{{ (r.title + '').toUpperCase() }}</a>
                </span>
                <v-icon v-if="i !== items.length - 1">{{
                    mdiChevronRight
                }}</v-icon>
            </span>
        </router-link>
    </div>
</template>
<script>
import { mdiChevronRight } from '@mdi/js'
export default {
    name: `BaseComponentLinks`,

    props: {
        items: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            mdiChevronRight
        }
    },

    methods: {
        selectRoute(route) {
            this.$router.push(route)
        }
    }
}
</script>
<style lang="css" scoped>
.selected-route {
    text-decoration: underline;
}
</style>
