// https://date-fns.org/docs/format
import format from 'date-fns/format'

export const formatDate = (date) => {
    if (!date) return ``
    if (typeof date.getMonth !== `function`) {
        date = new Date(date)
    }
    return format(date, `MMM d yyyy`)
}

export const formatDateShort = (date) => {
    if (!date) return ``
    if (typeof date.getMonth !== `function`) {
        date = new Date(date)
    }
    return format(date, `MM-dd-yyyy`)
}
export const formatDateTime = (date) => {
    if (!date) return ``
    if (typeof date.getMonth !== `function`) {
        date = new Date(date)
    }
    return format(date, `MM-dd-yyyy ppp`)
}
