<template>
    <div>
        <v-form
            v-if="submitted <= 1"
            id="sf_form"
            ref="sf-case-form"
            v-model="isValid"
            :disabled="submitted !== 0"
            method="POST"
            @submit="progressStage"
        >
            <div label="User Edit Pane">
                <v-row label="Names">
                    <v-col cols="12" sm="6">
                        <base-text-field
                            id="firstName"
                            v-model="firstName"
                            label="First Name"
                            :rules="[required, maxLength(100)]"
                            hide-details
                            dark
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <base-text-field
                            id="lastName"
                            v-model="lastName"
                            label="Last Name"
                            :rules="[required, maxLength(100)]"
                            hide-details
                            dark
                        />
                    </v-col>
                </v-row>
                <v-row label="Email Phone">
                    <v-col cols="12" sm="6">
                        <base-text-field
                            id="email"
                            v-model="email"
                            label="Email Address"
                            :rules="[required, maxLength(50)]"
                            hide-details
                            dark
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <base-text-field
                            id="phone"
                            v-model="phone"
                            v-mask="'(###)###-####'"
                            placeholder="(###)###-####"
                            label="Phone"
                            :rules="[required, maxLength(25)]"
                            hide-details
                            dark
                        />
                    </v-col>
                </v-row>

                <div label="User Entry">
                    <base-text-field
                        id="subject"
                        v-model="subject"
                        class="py-3"
                        label="Subject"
                        :rules="[required, maxLength(80)]"
                        hide-details
                        dark
                    />
                    <base-textarea
                        v-model="description"
                        class="py-3"
                        :rules="[required]"
                        label="Description"
                        hide-details
                        dark
                    />
                </div>

                <base-select
                    v-model="caseType"
                    title="Case Type"
                    label="Case Type"
                    :rules="[required]"
                    :items="caseTypeList"
                    class="py-3"
                    hide-details
                    dark
                />
                <v-row label="Reference Numbers">
                    <v-col cols="12" sm="6">
                        <base-text-field
                            id="modelName"
                            v-model="modelName"
                            label="Model"
                            :rules="[maxLength(30)]"
                            hide-details
                            dark
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <base-text-field
                            id="serialNumber"
                            v-model="serialNumber"
                            label="Getac Serial Number"
                            :rules="[maxLength(50)]"
                            hide-details
                            dark
                        />
                    </v-col>
                </v-row>
            </div>
            <div
                class="pt-3"
                :class="alignButton === 'right' ? 'd-flex align-end flex-column' : ''"
                @click="validateOnSubmission"
            >
                <base-btn
                    v-if="submitted === 0"
                    :block="$vuetify.breakpoint.smAndDown"
                    outlined
                    align="right"
                    :disabled="!isValid"
                    @click="submit"
                >
                    Submit
                </base-btn>
            </div>
        </v-form>
        <v-progress-circular v-if="submitted === 1" indeterminate color="primary" />
        <v-snackbar v-model="snackbar" :color="snackbarColor">
            {{ snackbarMessage }}

            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" color="white" @click="snackbar = false"> Close </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import gql from 'graphql-tag'
import { mask } from 'vue-the-mask'
export default {
    name: `SalesForceForm`,
    directives: { mask },
    props: {
        // eslint-disable-next-line vue/prop-name-casing
        alignButton: {
            type: String,
            default: ``
        },
        privateForm: {
            type: Boolean
        }
        /*
        serialNumber: {
            type: String,
            default: () => ``
        },
        modelName: {
            type: String,
            default: () => ``
        },
        subject: {
            type: String,
            required: false,
            default: ''
        },
        caseType: {
            type: String,
            required: false,
            default: ''
        }
            */
    },
    data() {
        return {
            required: (d) => !!d || `Required`,
            maxLength: (len) => (d) => d.length <= len || `Input length too long`,
            // action: `https://postman-echo.com/post`,
            // action: `https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8`,
            caseTypeList: [`Hardware`, `Software`, `Quote`, `Question`],
            firstName: '',
            lastName: '',
            submitted: 0,
            isValid: false,
            subject: ``,
            description: ``,
            email: ``,
            phone: '',
            modelName: '',
            caseType: '',
            serialNumber: '',
            snackbar: false,
            snackbarMessage: '',
            snackbarColor: 'error'
        }
    },
    watch: {
        submitted(key) {
            let state
            switch (key) {
                case 0:
                    state = `started`
                    break
                case 1:
                    state = `processing`
                    break
                case 2:
                    state = `complete`
                    break
                default:
                    state = `unknown`
            }

            this.$emit(state)
            this.$emit(`change`, state)
        }
    },
    mounted() {
        const user = this.$auth.user
        this.email = user.email
    },
    methods: {
        validateOnSubmission() {
            this.$refs[`sf-case-form`].validate()
        },
        submit() {
            if (this.isValid) {
                // submit form via graphql
                this.progressStage()
                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation createServiceSupportTicket($ticketInput: ServiceSupportTicketInput!) {
                                createServiceSupportTicket(ticketInput: $ticketInput) {
                                    success
                                    error
                                    uid
                                    complete
                                }
                            }
                        `,
                        variables: {
                            ticketInput: {
                                name: `${this.firstName} ${this.lastName}`,
                                emailAddress: this.email,
                                phone: this.phone,
                                subject: this.subject,
                                description: this.description,
                                caseType: this.caseType,
                                model: this.modelName,
                                serial: this.serialNumber
                            }
                        }
                    })
                    .then((data) => {
                        this.progressStage()
                        if (data.data.createServiceSupportTicket.success) {
                            this.snackbarColor = 'success'
                            this.actionReceived(
                                `Ticket ${data.data.createServiceSupportTicket.uid} has been created successfully`
                            )
                        } else {
                            this.snackbarColor = 'error'
                            this.actionReceived(
                                `Failed to create ticket: ${data.data.createServiceSupportTicket.error} `
                            )
                        }
                    })
                    .catch((errors) => {
                        this.snackbarColor = 'error'
                        this.actionReceived(errors)
                    })
            }
        },
        progressStage() {
            this.submitted++
            if (this.submitted >= 2) this.submitted = 0
            console.debug(this.submitted)
        },
        actionReceived(message) {
            this.snackbar = true
            this.snackbarMessage = message
        }
    }
}
</script>
