import axios from 'axios'

export const postContactForm = ({ name, email, subject, desc }) => {
    return axios({
        method: `post`,
        url: `/contact-form`,
        data: {
            name,
            email,
            subject,
            desc
        }
    })
}
