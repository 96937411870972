const state = {
    cart: localStorage.getItem(`cart`)
        ? JSON.parse(localStorage.getItem(`cart`))
        : []
}

const getters = {
    cart: (state) => state.cart,
    length: (state) => state.cart.length
}

const actions = {
    /**
     * @param {*} state
     * @param {*} commit
     * @param {*} dispatch
     * @param {*} item
     */
    add({ state, commit, dispatch }, item) {
        let notification
        if (!validate(item)) {
            notification = `There was an error performing this action.`
        } else {
            const current = state.cart.find((object) => object.pn === item.pn)
            if (!current) {
                commit(`PUSH`, { item: item })
            } else if (current) {
                commit(`PUSH`, { item: item })
            } else if (current && current.model !== item.model) {
                commit(`PUSH`, { item: item })
            } else if (current && current.model === item.model) {
                commit(`UPDATE`, { item: item })
            }
        }
        dispatch(`notification/set`, notification, { root: true })
    },
    /**
     * @param {*} state
     * @param {*} commit
     * @param {*} dispatch
     * @param {*} item
     */
    remove({ state, commit, dispatch }, item) {
        let notification
        if (!validate(item)) {
            notification = `There was an performing this action.`
        } else {
            notification = `${item.partSpec} is unavailable to remove from the cart.`
            // const current = state.cart.find((object) => object.pn === item.pn)
            const current = state.cart.filter(function (object) {
                return object.pn === item.pn && object.serials === item.serials
            })
            if (current) {
                commit(`REMOVE`, { item: current[0] })
                notification = `${item.partSpec} has been removed from the cart.`
            }
        }
        dispatch(`notification/set`, notification, { root: true })
    },
    /**
     * Merges input items with cart state and removes
     * any line items that do not exist in state.
     * @param {*} state
     * @param {*} commit
     * @param {*} items
     */
    populate({ state, commit }, items) {
        if (items.every((item) => validate(item))) {
            const merged = []
            state.cart.forEach((cartItem) => {
                const found = items.find((item) => item.pn === cartItem.pn)
                if (found) {
                    merged.push({
                        ...cartItem,
                        ...found
                    })
                }
            })
            commit(`SET`, merged)
        }
    },
    /**
     * @param {*} commit
     */
    clear({ commit }) {
        commit(`CLEAR`)
    }
}

const mutations = {
    /**
     * @name PUSH
     * @param {*} state
     * @param {*} item
     */
    PUSH(state, { item }) {
        state.cart.unshift(item)
        updateLocal(state.cart)
    },
    /**
     * @name UPDATE
     * @param {*} state
     * @param {*} item
     */
    UPDATE(state, { item }) {
        const current = state.cart.find((obj) => obj.pn === item.pn)
        if (current) {
            const newSerials = current.serials.filter(
                (o) => item.serials.indexOf(o) === -1
            )
            current.serials = item.serials.concat(newSerials)
            current.quantity = current.serials.length
            updateLocal(state.cart)
        }
    },
    /**
     * @name REMOVE
     * @param {*} state
     * @param {*} item
     */
    REMOVE(state, { item }) {
        const index = state.cart.findIndex((object) => object === item)
        if (index !== -1) {
            state.cart.splice(index, 1)
            updateLocal(state.cart)
        }
    },
    /**
     * @name CLEAR
     * @param {*} state
     */
    CLEAR(state) {
        state.cart = []
        updateLocal(state.cart)
    },
    /**
     * @name SET
     * @param {*} state
     * @param {*} items
     */
    SET(state, items) {
        state.cart = items
        updateLocal(state.cart)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

function updateLocal(items) {
    localStorage.setItem(`cart`, JSON.stringify(filter(items)))
}

function filter(items) {
    return items.map((item) => {
        return {
            pn: item.pn,
            quantity: item.quantity,
            partSpec: item.partSpec,
            imgUrl: item.imgUrl,
            model: item.model,
            serials: item.serials,
            isHighValue: item.isHighValue,
            storeDate: new Date()
        }
    })
}

function validate(item) {
    return item && item.pn && item.partSpec
}
