import axios from 'axios'
import qs from 'qs'
export const rmaHistoryByUserId = ({
    sortBy,
    sortDesc,
    page,
    itemsPerPage,
    filterText
}) => {
    return axios({
        method: `get`,
        url: `/rma-manager/rma/history`,
        params: {
            sortBy,
            sortDesc,
            page,
            itemsPerPage,
            filterText
        },
        paramsSerializer: function(params) {
            return qs.stringify(params, { indices: false })
        }
    })
}

export const rmaDetailsByUid = ({ uid }) => {
    return axios({
        method: `get`,
        url: `/rma-manager/rma/details`,
        params: {
            uid
        }
    })
}

export const userCancelRma = ({ rmaNumber = `` }) => {
    return axios({
        method: `post`,
        url: `/rma-manager/rma/cancel`,
        data: {
            rmaNumber
        }
    })
}

export const repairSummary = ({ rmaNumber = `` }) => {
    return axios({
        method: `get`,
        url: `/rma-manager/rma/repair-summary?rmaNumber=${rmaNumber}`
    })
}
