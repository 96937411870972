<template>
    <div class="d-flex flex-wrap">
        <div v-for="(item, i) in items" :class="classes">
            <span v-if="!item.disabled">
                <a
                    :class="selected === item.comp ? 'selected-comp' : ''"
                    @click="selectComp(item.comp)"
                >
                    {{ item.text.toUpperCase() }}
                </a>
                <span v-if="i !== items.length - 1"> / </span>
            </span>
        </div>
    </div>
</template>
<script>
export default {
    name: `BaseRouterLinks`,

    props: {
        items: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            itemSelected: null
        }
    },
    computed: {
        selected: {
            get() {
                if (!this.itemSelected) return this.items[0].comp
                else return this.itemSelected
            },
            set(v) {
                this.itemSelected = v
            }
        },
        classes() {
            return [`mr-2`, `font-weight-medium`, `accent--text`, `pointer`]
        }
    },
    methods: {
        selectComp(comp) {
            this.selected = comp
            this.$emit(`changeComp`, comp)
        }
    }
}
</script>
<style lang="css">
.selected-comp {
    text-decoration: underline;
}
</style>
