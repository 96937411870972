<script>
import ValidateWarranty from '@/components/validate_warranty/validate_warranty.vue'
import UnitCard from '@/components/unit_card/unit_card.vue'
import ProblemForm from '../forms/problem_form.vue'
export default {
    name: `ProblemStep`,
    components: {
        ValidateWarranty,
        UnitCard,
        ProblemForm
    },
    props: {
        value: { type: Object, default: () => {} },
        userEmail: { type: String, required: true },
        serial: {
            type: String,
            default: ``
        }
    },

    data() {
        return {
            fullReportedProblemList: {},
            shipItemsList: [],
            serialIsValid: false,
            problemIsValid: false,
            showErrors: false,
            unitData: {}
        }
    },

    computed: {
        userCanCreateOnSerial() {
            return this.unitData && typeof this.unitData.iw === `boolean` && !this.unitData.iw
        },
        formValid() {
            return this.userCanCreateOnSerial && this.problemIsValid
        }
    },
    methods: {
        resetForm() {
            this.$refs.problemForm.resetForm()
            this.$refs.fileUploader.reset()
        },
        validateOnContinue() {
            this.$refs.problemForm.$children[0].validate()
            if (!this.formValid) this.showErrors = true
            const self = this
            setTimeout(() => {
                self.showErrors = false
            }, 4000)
        }
    }
}
</script>

<template>
    <div>
        <problem-form
            ref="problemForm"
            col-md="6"
            :serial-is-valid="userCanCreateOnSerial"
            @form-is-valid="problemIsValid = $event"
            @problemInput="$emit('problemInput', $event)"
        >
            <template v-slot:serial-validation>
                <v-card v-if="!unitData.serial" min-height="168" flat color="grey" class="mb-5" />

                <unit-card
                    v-else
                    min-height="168"
                    :warranty-type="unitData.warrantyType"
                    :serial="unitData.serial"
                    :model="unitData.model"
                    :model-family="unitData.modelFamily"
                    :iw="unitData.iw"
                    :is-recycle-page="true"
                    :allow-oow="unitData.allowOow"
                    :rma-exists="unitData.rmaExists"
                    :end-device-warranty="unitData.endDeviceWarranty"
                    class="mb-5"
                    elevation="0"
                />

                <validate-warranty
                    max-width="250"
                    :outside-serial="serial"
                    @serialData="
                        unitData = $event
                        $emit('unitInput', $event)
                    "
                    @isValid="serialIsValid = $event"
                />
            </template>
        </problem-form>
        <div>
            <div class="fields-missing justify-end" :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : 'd-flex'">
                <v-alert
                    v-if="showErrors"
                    block
                    type="error"
                    :class="!$vuetify.breakpoint.smAndDown ? 'mt-2 mr-10' : 'mt-2'"
                >
                    Missing Required Fields!
                </v-alert>
                <v-card v-else flat height="72" :class="!$vuetify.breakpoint.smAndDown ? 'mt-2 mr-10' : 'mt-0'">
                </v-card>
            </div>
        </div>
        <div class="mb-4">
            <div class="justify-end fields-missing" :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : 'd-flex'">
                <div @click="validateOnContinue">
                    <base-btn
                        data-cy="btn-continue-to-contact"
                        :disabled="!formValid"
                        :block="$vuetify.breakpoint.smAndDown"
                        color="primary"
                        large
                        :class="!$vuetify.breakpoint.smAndDown ? 'mt-0 mr-10' : 'mt-0'"
                        outlined
                        @click="$emit('continue')"
                    >
                        Continue
                    </base-btn>
                </div>
            </div>
        </div>
    </div>
</template>
