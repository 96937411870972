export const lightTheme = {
    primary: `#DB5120`, // Getac Orange
    secondary: `#8b8d8d`, // Getac Silver
    accent: `#003a5d`, // Getac Blue
    getac_green: `#7b7256`, // Getac Green
    getac_grey: `#59595A` // Getac Grey
}

export const darkTheme = {
    primary: `#DB5120`
}
